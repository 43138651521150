@import "~include-media/dist/include-media";
@import '../../../styles/common';
$bounce-color: #fae098;

@keyframes bounceOut {
  0%{ box-shadow: 0 0 0 4px $bounce-color; opacity:1;}
  25%{ box-shadow: 0 0 0 1px $bounce-color; opacity:1;}
  50%{ box-shadow: 0 0 0 7px $bounce-color; opacity:1; }
  75%{ box-shadow: 0 0 0 4px $bounce-color; opacity:1;}
  100%{ box-shadow: 0 0 0 5px $bounce-color; opacity:1;}
}


@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.galleryWrapper {
  color: #434343;

  :global(.lg-image) {
    padding: rem(legacy-rem(20));
}

  :global(.lg-backdrop) {
    background-color: rgba(255, 255, 255, 0.85);
  }

  :global(.lg-zoom-in) {
    display: none;
  }

  :global(.lg-counter) {
    display: none;
  }

  :global(.lg-sub-html) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  :global(.lg-outer .lg-item:not(.lg-start-end-progress)) {
    padding: rem(legacy-rem(20));
    background: url(/public/images/spinner.gif) no-repeat scroll center center transparent;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  :global(.lg-icon) {
    font-size: rem(24);
  }

  :global(.lg-close) {
    width: 60px;
    height: 60px;
    background-color: #ffca39;
    border-bottom-left-radius: 90%;
    color: #434343;
    transition: all 0.3s;

    &:after {
      margin-right: -14px;
      content: url('../../../../public/images/plus-svg.svg');
    }

    &:hover {
      -webkit-transition: opacity 0.7s linear;
      -o-transition: opacity 0.7s linear;
      -moz-transition: opacity 0.7s linear;
      transition: all 0.3s;
      backface-visibility: hidden;
      perspective: 1000px;
      background-color: #fae098;
      animation:bounceOut .4s linear;
      box-shadow: 0 0 0 5px $bounce-color; opacity:1;
    }
  }

  :global(.light-gallery-link){
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 350px;
    padding: 0 0 45px;
    &:hover {
      cursor: pointer;
    }
    &:hover :global(.video-text):after{
      transform: scaleX(1);
    }
  }
  :global(.video-text){
    color: $primary;
    font-weight: 700;
    border-radius: 10px;
    margin-right: rem(13);
    font-family: $bodyFont;
    font-size: rem(legacy-rem(22));
    line-height: rem(legacy-rem(40));
    letter-spacing: rem(.5);
    display: flex;
    text-decoration: none;
    position: relative;
    align-items: center;
    transition: all 0.5s ease-in-out;

    &:hover {
      text-decoration: none;
      color: $primary;

      &:after {
        transform: scaleX(1);
      }
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 5px;
      background: $brand-pink;
      mask-image: url("../images/underline.svg");
      -webkit-mask-image: url("../images/underline.svg");
      -webkit-mask-position:center;
      -webkit-mask-size: 100% 100%;
      -webkit-mask-repeat: no-repeat;
      mask-position:center;
      mask-size: 100% 100%;
      mask-repeat: no-repeat;
      position: absolute;
      left: 0;
      bottom: rem(-10);
      transition: all 0.5s ease-in-out;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 5px;
      background: $brand-blue;
      mask-image: url("../images/underline.svg");
      -webkit-mask-image: url("../images/underline.svg");
      -webkit-mask-position: center;
      -webkit-mask-size: 100% 100%;
      -webkit-mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100% 100%;
      mask-repeat: no-repeat;
      position: absolute;
      left: 0;
      bottom: rem(-10);
      transition: all 0.5s ease-in-out;
      transform: scaleX(0);
      transform-origin: 0 center;
    }
  }
  :global(.bottom-border){
    &:after {
      content: '';
      display: block;
      width: rem(50);
      height: rem(24);
      background: url("../images/buttonArrow.svg") no-repeat;
    }
  }

  :global(.lg-video-cont) {
    transform: unset !important;
    transition-duration: 0.75s !important;
  }

  :global(.lg-components) {
    transition-delay: 1.75s !important;
  }

  :global(.lg-sub-html) {
    transition-delay: 1.75s !important;
  }
}
.galleryContainer {
  &.sectionGallery {
    width: 100%;
    height: 100%;

    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  a {
    display:inline-block;
    width:100%;
    height: 100%;
    position:relative;
    background-color: transparent;
  }
}

.button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}