@import "~include-media/dist/include-media";
@import '../../../styles/common';

.btnPlay {
  min-height: auto;
  width: rem(legacy-rem(95));
  height: rem(legacy-rem(95));
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../../../public/images/btnPlay-bg.svg');
  background-size: cover;
  background-color: transparent;
  background-position: center;

  svg {
    width: rem(legacy-rem(15));

    path {
      transition: all 0.3s ease-in-out;
      fill: #434343;
    }
  }

  @include media('>=lg') {
    width: rem(legacy-rem(180));
    height: rem(legacy-rem(180));

    svg {
      width: rem(legacy-rem(25));
    }

    &:hover {
      transition: all 0.3s ease-in-out;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }


  @include media('<lg') {
    width: rem(108);
    height: rem(108);

    svg {
      width: rem(legacy-rem(15));
    }
  }
}

.btnPlayHover {
  svg {
    path {
      fill: $white;
    }
  }
}